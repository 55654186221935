<template>
  <main class="relative w-full">
    <Carousel />
    <ProductList title="推薦商品" />
    <About />
    <NewsList />
  </main>
</template>

<script>
import Carousel from "@/components/home/Carousel.vue";
import ProductList from "@/components/home/ProductList.vue";
import About from "@/components/home/About.vue";
import NewsList from "@/components/home/NewsList.vue";

export default {
  name: "HomeView",
  components: {
    Carousel,
    About,
    ProductList,
    NewsList,
  },
  created() {
    const meta_data = this.$store.getters.page_meta_data("首頁");
    this.$PageInit(
      this,
      meta_data.Title,
      meta_data.Content,
      meta_data.Image == "" ? "" : this.$ImageUrl(meta_data.Image)
    );
  },
};
</script>
