<template>
  <div class="fixed top-0 left-0 z-30 w-full">
    <Announcement ref="Announcement" />
    <div class="relative">
      <div class="w-full relative z-10 h-[70px] bg-white text-light-black">
        <div
          class="relative z-10 flex items-center justify-between w-full h-full px-5"
        >
          <router-link
            to="/"
            class="relative z-10 flex items-center h-full transform"
          >
            <img src="/img/logo.svg" class="block h-[70%]" />
          </router-link>

          <!-- <div class="flex items-center h-full"> -->
          <ol class="items-center hidden h-full mr-5 lg:flex">
            <li
              v-for="(item, item_index) in menu_list"
              :key="`list_item_${item_index}`"
              class="flex items-center h-full px-1"
            >
              <router-link
                class="flex items-center px-3 py-1 text-sm transition-colors duration-300 bg-transparent rounded-md text-zinc-600 md:hover:bg-zinc-200 hover:md:text-zinc-600"
                :to="item.link"
              >
                {{ item.title }}
              </router-link>
            </li>
          </ol>
          <ol class="flex items-center h-full lg:-mr-2">
            <li class="hidden h-full lg:block">
              <button
                @click="$router.push('/account/login')"
                class="flex items-center justify-center w-6 h-full px-5 transition-colors duration-300 text-zinc-600 hover:md:text-secondary"
              >
                <i class="text-current icon-user"></i>
              </button>
            </li>
            <!-- <li class="hidden h-full lg:block">
                <button
                  @click="OpenSearchDialog"
                  class="flex items-center justify-center w-6 h-full px-5 transition-colors duration-300 text-zinc-600 hover:md:text-secondary"
                >
                  <i class="text-current icon-magnifying-glass"></i>
                </button>
              </li> -->
            <li class="h-full mr-5 lg:mr-0">
              <button
                @click="$store.commit('SetShopCartStatus', true)"
                class="relative flex items-center justify-center h-full px-2 transition-colors duration-300 text-zinc-600 hover:md:text-secondary"
              >
                <div class="relative w-6 text-current">
                  <span
                    class="absolute -right-1 z-10 px-1 text-xs font-bold h-4 min-w-[16px] block text-white transform rounded-full -top-1 bg-secondary scale-[.9]"
                    >{{ shop_cart_count }}</span
                  >
                  <i
                    class="relative z-0 text-xl text-current icon-cart-shopping"
                  ></i>
                </div>
              </button>
            </li>
            <li class="block h-full lg:hidden">
              <button
                @click="$emit('menu-action')"
                class="block h-full transition-colors duration-300 text-zinc-600 lg:hidden hover:md:text-zinc-500"
              >
                <i v-show="!menu_status" class="text-current icon-menu"></i>
                <i v-show="menu_status" class="text-current icon-close"></i>
              </button>
            </li>
          </ol>
          <!-- </div> -->
        </div>
      </div>
      <ShopCartMessage ref="ShopCartMessage" />
    </div>
  </div>
</template>

<script>
import Announcement from "@/components/mainHeader/Announcement.vue";
import ShopCartMessage from "@/components/mainHeader/ShopCartMessage.vue";
export default {
  name: "MainHeader",
  components: {
    Announcement,
    ShopCartMessage,
  },
  data() {
    return {
      menu_list: [
        {
          title: "認證中古車",
          sub_title: "Scooters",
          link: "/scooters",
        },
        {
          title: "線上商店",
          sub_title: "Products",
          link: "/collections",
        },
        // {
        //   title: "關於我們",
        //   sub_title: "About Us",
        //   link: "/about",
        // },
        {
          title: "最新消息",
          sub_title: "News",
          link: "/news",
        },
        // {
        //   title: "影音專區",
        //   sub_title: "Videos",
        //   link: "/video",
        // },
        {
          title: "聯絡我們",
          sub_title: "Contact",
          link: "/contact",
        },
      ],
      drop_down: false,
    };
  },
  props: {
    menu_status: {
      require: true,
      type: Boolean,
    },
  },
  methods: {
    OpenSearchDialog() {
      this.$store.commit("SetSearchDialog", true);
    },
  },
  computed: {
    shop_cart_data() {
      return this.$store.state.shopcart_module.shopcart;
    },
    shop_cart_count() {
      let count = 0;
      this.shop_cart_data.forEach((item) => {
        count += parseInt(item.amount);
      });
      return count;
    },
    announcement_list() {
      return this.$store.getters.announcement_list;
    },
  },
};
</script>
