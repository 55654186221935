<template>
  <section class="relative z-10 w-full py-10 md:py-20 bg-zinc-100">
    <div class="main_container">
      <header class="relative w-full mb-10 text-center md:mb-14">
        <h2 class="mb-2 text-3xl font-medium text-light-black md:text-4xl">
          最新上架車款
        </h2>
        <p class="w-full mb-3 text-zinc-500">選擇最適合您的二手車款</p>
      </header>

      <div class="relative mb-10">
        <ul class="flex flex-wrap items-stretch -mx-2 overflow-hidden">
          <li
            class="flex-shrink-0 w-full px-2 mb-5 sm:w-1/2 lg:w-1/4 xl:mb-0"
            v-for="(item, item_index) in product_data"
            :key="`product_${item_index}`"
          >
            <ProductCard :ref="item.Title" :product_data="item" />
          </li>
        </ul>
      </div>

      <div class="flex justify-center">
        <router-link to="/scooters" class="px-10 py-3 main_button md:px-14">
          查看所有車輛
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import ProductCard from "@/components/productList/ProductCard.vue";
import { getProductListData } from "@/common/data_convert";
export default {
  name: "ProductListSection",
  components: {
    ProductCard,
  },
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    product_data() {
      return getProductListData(
        this.$store.getters.home_promote_products
      ).slice(0, 4);
    },
  },
  mounted() {},
};
</script>
