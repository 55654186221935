<template>
  <section class="relative z-10 py-20 bg-white lg:pt-40 lg:mb-20">
    <div class="main_container">
      <header class="relative w-full mb-10 text-center">
        <h2 class="mb-2 text-3xl font-semibold text-light-black md:text-4xl">
          我們是
          <span
            class="text-transparent bg-gradient-to-r from-secondary to-primary bg-clip-text"
            >頑電動能</span
          >
        </h2>
        <p class="w-full text-zinc-500">
          {{ $GetColumn("home_about_sub_title") }}
        </p>
      </header>
      <div class="w-full mb-10 aspect-video">
        <img
          :src="$ImageUrl($GetColumn('home_about_image'))"
          class="object-cover w-full h-full"
        />
      </div>
      <div class="flex flex-wrap items-start -mx-5">
        <div class="w-full px-5 mb-10 md:w-1/2 md:mb-0">
          <h3 class="mb-1 text-base font-medium md:mb-5 md:text-xl">
            {{ $GetColumn("home_about_title_1") }}
          </h3>
          <div
            class="text-sm font-light text-gray md:text-base"
            v-html="$GetColumn('home_about_content_1')"
          ></div>
        </div>
        <div class="w-full px-5 md:w-1/2">
          <h3 class="mb-1 text-base font-medium md:mb-5 md:text-xl">
            {{ $GetColumn("home_about_title_2") }}
          </h3>
          <div
            class="text-sm font-light text-gray md:text-base"
            v-html="$GetColumn('home_about_content_2')"
          ></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeAboutSection",
};
</script>
