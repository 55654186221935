<template>
  <section class="relative z-10 w-full py-10 overflow-x-hidden bg-zinc-100">
    <div class="main_container">
      <ol class="flex flex-wrap items-start -mx-5 md:-mx-10 lg:-mx-14">
        <li class="w-full px-5 mb-14 md:mb-0 md:px-10 md:w-1/3 lg:px-14">
          <div class="text-center">
            <div class="w-10 mx-auto mb-5 text-center">
              <img src="/img/shopping-bag.png" class="block w-full" />
            </div>
            <h4 class="mb-2 font-medium">改裝配件與週邊</h4>
            <p class="mb-5 text-sm text-zinc-500">
              嚴選多家品牌的改裝配件與週邊商品，商品皆經過內部選品與試用，只提供最好的商品給您選擇
            </p>
            <router-link
              to="/collections"
              class="justify-center text-sm link_text_btn"
            >
              瀏覽商品
              <i class="text-xs transform icon-chevron-left -scale-100"></i
            ></router-link>
          </div>
        </li>
        <li class="w-full px-5 mb-14 md:mb-0 md:px-10 md:w-1/3 lg:px-14">
          <div class="text-center">
            <div class="w-10 mx-auto mb-5 text-center">
              <img src="/img/buy.png" class="block w-full" />
            </div>
            <h4 class="mb-2 font-medium">二手車收購</h4>
            <p class="mb-5 text-sm text-zinc-500">
              提供PBGN車款收購服務，我們不只提供現場估價、網路估價還能預約專人到府看車估價
            </p>
            <a
              :href="$GetColumn('company_messenger')"
              target="_blank"
              class="justify-center text-sm link_text_btn"
            >
              線上諮詢
              <i class="text-xs transform icon-chevron-left -scale-100"></i
            ></a>
          </div>
        </li>
        <li class="w-full px-5 mb-14 md:mb-0 md:px-10 md:w-1/3 lg:px-14">
          <div class="text-center">
            <div class="w-10 mx-auto mb-5 text-center">
              <img src="/img/customer-support.png" class="block w-full" />
            </div>
            <h4 class="mb-2 font-medium">專業維修保養</h4>
            <p class="mb-5 text-sm text-zinc-500">
              精湛的技師團隊加上最專業的設備提供您最完整的服務，幫助您在最快的時間了解車輛狀況
            </p>
            <a
              :href="$GetColumn('company_messenger')"
              target="_blank"
              class="justify-center text-sm link_text_btn"
            >
              立即預約
              <i class="text-xs transform icon-chevron-left -scale-100"></i
            ></a>
          </div>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactFooter",
  data() {
    return {};
  },
};
</script>
