<template>
  <section class="relative z-10 w-full pb-20">
    <div class="main_container">
      <header class="relative w-full text-center mb-14">
        <h2 class="mb-2 text-3xl font-medium text-light-black md:text-4xl">
          最新消息
        </h2>
        <p class="w-full mb-3 text-zinc-500">優惠活動 / 重要公告 / 資訊分享</p>
      </header>

      <ol class="flex flex-wrap items-stretch -mx-5">
        <li
          v-for="(item, item_index) in news_data"
          :key="`news_${item_index}`"
          class="w-full px-5 mb-10 lg:w-1/3"
        >
          <div class="flex flex-col w-full h-full">
            <router-link
              :to="`/news/${item.NewsID}`"
              class="block w-full mb-5 overflow-hidden aspect-video"
            >
              <img
                :src="$ImageUrl(item.Image1)"
                :alt="item.Title"
                class="block object-cover w-full h-full transition-all duration-500 transform hover:scale-105"
              />
            </router-link>
            <div class="flex items-center mb-3">
              <p class="mr-4 text-sm work_sans md:text-base text-zinc-400">
                {{ item.created_at.slice(0, 10) }}
              </p>
              <p class="text-sm text-secondary">{{ item.CategoryTitle }}</p>
            </div>

            <router-link
              :to="`/news/${item.NewsID}`"
              class="mb-3 text-black transition-all duration-300 md:text-xl hover:text-transparent sm:text-2xl bg-gradient-to-r from-secondary to-primary bg-clip-text"
            >
              {{ item.Title }}
            </router-link>
            <div class="flex-1 mb-8 text-sm sm:text-base text-zinc-500">
              {{ GetContent(item.Content) }}
            </div>
          </div>
        </li>
      </ol>

      <div class="flex justify-center">
        <router-link to="/news" class="px-10 py-3 main_button md:px-14">
          查看更多文章
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NewsListSection",
  computed: {
    news_data() {
      return this.$store.state.news_data.slice(0, 4);
    },
  },
  methods: {
    GetContent(val) {
      let msg = val.replace(/<\/?[^>]*>/g, ""); //去除HTML Tag
      msg = msg.replace(/[|]*\n/, ""); //去除行尾空格
      msg = msg.replace(/&npsp;/gi, ""); //去掉npsp
      msg = msg.replace(/&nbsp;/gi, ""); //&nbsp;

      return msg.length > 50 ? msg.slice(0, 50) + "..." : msg;
    },
  },
};
</script>
