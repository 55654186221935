<template>
  <aside
    ref="MainContent"
    class="fixed top-0 bottom-0 z-30 w-full overflow-x-hidden overflow-y-auto transform left-full"
  >
    <div
      data-dialog-box
      class="relative z-10 flex flex-col w-auto h-full ml-10 bg-white"
    >
      <ol class="w-full px-10 pt-10">
        <li
          class="w-full px-5"
          v-for="(item, item_index) in menu_list"
          :key="`item_${item_index}`"
        >
          <router-link
            @click.native="$emit('menu-action')"
            class="block py-4 text-xl font-light"
            :to="item.link"
          >
            {{ item.title }}
          </router-link>
        </li>
      </ol>

      <ol class="w-full px-10 pt-4">
        <li class="w-full px-5 pt-4 border-t border-zinc-300">
          <router-link
            class="block py-4 text-xl font-light"
            to="/account/account_edit"
            v-if="CheckLogin()"
            @click.native="$emit('menu-action')"
          >
            會員中心
          </router-link>
          <router-link
            class="block py-4 text-xl font-light"
            to="/account/login"
            v-else
            @click.native="$emit('menu-action')"
          >
            登入 / 註冊
          </router-link>
        </li>
      </ol>
    </div>

    <div
      data-dialog-background
      @click="$emit('menu-action')"
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-black bg-opacity-10 backdrop-blur-sm"
    ></div>
  </aside>
</template>

<script>
import { getLocalStorage } from "@/common/cookie";
import { MainMenu } from "@/gsap/MainMenu.js";
export default {
  name: "MainMenu",
  props: {
    menu_status: {
      require: true,
      type: Boolean,
    },
  },
  data() {
    return {
      menu_list: [
        {
          title: "認證中古車",
          link: "/scooters",
        },
        {
          title: "線上商店",
          link: "/collections",
        },
        // {
        //   title: "關於我們",
        //   link: "/about",
        // },
        {
          title: "最新消息",
          link: "/news",
        },
        // {
        //   title: "影音專區",
        //   link: "/video",
        // },
        {
          title: "聯絡我們",
          link: "/contact",
        },
      ],
      menu_gsap: null,
    };
  },
  methods: {
    CheckLogin() {
      return getLocalStorage("account_token") != null;
    },
  },
  watch: {
    menu_status() {
      if (this.menu_status) {
        this.menu_gsap.open();
      } else {
        this.menu_gsap.close();
      }
    },
  },
  mounted() {
    this.menu_gsap = new MainMenu(this.$refs.MainContent);
    this.menu_gsap.reset();
  },
};
</script>
